<template>
    <div class="layout">
         <header-nav></header-nav>
        <p class="main-view-box">
            <router-view></router-view>
        </p>
        <suspend-right></suspend-right>
    </div>
</template>
<script>
import HeaderNav from '@/components/header.vue';
import suspendRight from './suspendRight.vue';

export default {
    name: 'Layout',
    components: {
        HeaderNav,
        suspendRight
    }, 
}
</script>
<style lang="scss" scoped>
    .layout {
        position: relative;
        height: 100%;
        overflow: hidden;
        .main-view-box{
            position: relative;
            height: 100%;
       }
    }
</style>