<template>
    <div class="suspend-right">
        <ul>
            <li v-for="c in categoryList" :key="c.key">
                <img :src="c.image"/>
                <p>{{ c.label }}</p>
            </li>
        </ul>
        <div class="popUp">
                <p class="title">联系电话</p>
                <p class="content">159 0095 9696</p>
                <img src="@/assets/img/footer/wechat.png"/>
            </div>
    </div>

</template>
<script>
export default {
    name: 'SuspendRight',
    data() {
        return {
            categoryList: [
                {
                    label: '在线咨询',
                    key: 'kefu',
                    image: require("@/assets/img/home/suspend/kefu.png")
                },
                {
                    label: '联系我们',
                    key: 'feiji',
                    image: require("@/assets/img/home/suspend/feiji.png")
                },
                {
                    label: '公众号',
                    key: 'gzh',
                    image: require("@/assets/img/home/suspend/gzh.png")
                },
                {
                    label: '抖音号',
                    key: 'douyin',
                    image: require("@/assets/img/home/suspend/douyin.png")
                }
            ]

        }
    },
}
</script>
<style lang="scss" scoped>
    .suspend-right {
        position: fixed;
        top: 3rem;
        right: 0.4rem;
        width: 1.3rem;
        height: 3.34rem;
        z-index: 100;
        overflow: hidden;
        ul {
            position: absolute;
            padding: 0.2rem 0.2rem 0.16rem 0.12rem;
            background: url('../../assets/img/home/suspend/contract-outer.png') no-repeat;
            background-size: 100% 100%;
            height: 100%;
            top: 0;
            right: 0;
            width: 1.3rem;
            display: flex;
            flex-direction: column;
						justify-content: spa;
            align-items: center;
            li {
                flex: 1;
                // padding-top: 0.2rem;
                // padding-bottom: 0.125rem;
                width: 70%;
                text-align: center;
                cursor: pointer;

                p {
                    color: #F5F5FF;
                    font-size: 0.08rem;
                    margin-bottom: 0.1rem;
                    transform: scale(0.95);
                }
                img {
									margin-top: 0.1rem;
                    height: 0.25rem;
                }
                &:nth-child(n + 2) {
                    border-top: 1px solid;
                    border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, RGBA(84, 212, 247, 0.618) 50%, rgba(255, 255, 255, 0) 100%) 1 1 1 1;

                }
            }
        }
        .popUp {
                position: absolute;
                // left: 0;
                right: 1.2rem;
                top: 0.2rem;
                width: 2rem;
                height: 2.7rem;
                background: url('../../assets/img/home/suspend/phone-outer.png') no-repeat;
                background-size: 100% 100%;
                text-align: center;
                font-size: 0.16rem;
                color: #FFFFFF;
                font-family: MicrosoftYaHei;
                // line-height: 1.31rem;
                letter-spacing: 2px;
                opacity: 0;
                // transform: translateX(-20%);
                // transition: all 0.3s ease-in-out;
                // transform-origin: bottom center;


                .title {
                    padding: 0.256rem 0 0.08rem 0;
                }
                .content {
                    margin-bottom: 0.128rem;
                }
                img {
                    position: relative;
                    width: 1.6rem;
                }
            }

            &:hover .popUp {
                opacity: 1;
                // transform: translateX(0);
            }
            &:hover {
                overflow:visible;
            }

    }
</style>
